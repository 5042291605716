import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import MarkDown from 'react-markdown';
import axios from 'axios';

import ChatBotScrollButton from './ChatBotScrollButton';
import ChatBotInput from './ChatBotInput';

import { BiDotsHorizontal } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { BsInfoCircle } from 'react-icons/bs';
import { useMutation } from '@tanstack/react-query';

import classNames from 'classnames';
import classes from './style.module.css';
import logoWhite from '../../assets/logos/cap_light.svg';
import logoDark from '../../assets/logos/cap_dark.svg';

import useChatStream from '../../hooks/useChatStream';

import { getRelativeTimeFromTimestamp } from '../../utilities/lib';
import { ENQUIRY_TAGS, INITIAL_CHAT_DETAILS } from '../../utilities/constants';
import { sendChatMessage } from '../../services/chat';

const ChatMessageList = () => {
    const messageListRef = useRef(null);

    const { isPending, isSuccess, error, mutate } = useMutation({ mutationFn: sendChatMessage });
    const { data, isFetching, onAddNewUserMessage } = useChatStream(isSuccess);

    const handleSubmitQuery = useCallback((message) => {
        onAddNewUserMessage(message);
        mutate({ message });
    }, [mutate, onAddNewUserMessage]);

    const renderCAPTyping = useCallback(() => {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={classes.messageListItem}
            >
                <div className={classNames({
                    [classes.messageListItemIcon]: true,
                    [classes.messageListNoMargin]: true
                })}>
                    <img src={logoDark} alt="CAP" className={classes.messageListLogo} />
                </div>
                <div className={classNames({
                    [classes.messageListItemWrapper]: true,
                    [classes.animationFading]: true,
                })}>
                    <BiDotsHorizontal /> 
                </div>
            </motion.div>
        );
    }, []);

    const renderCAPMessage = useCallback((message) => {
        return (
            <motion.div
                key={message.id}
                initial={{ transform: "translateX(-100%)" }}
                animate={{ transform: "translateX(0)" }}
                className={classes.messageListItem}
            >
                <div className={classes.messageListItemIcon}>
                    <img src={logoDark} alt="CAP" className={classes.messageListLogo} />
                </div>
                <div className={classes.messageSuperFlex}>
                    <div className={classNames([classes.messageListItemWrapper, 'text-wrap'])}>
                        <MarkDown>
                            {message.content[0].text.value}
                        </MarkDown>
                    </div>
                    <div className={classes.messageListItemDetails}>Kemi - {getRelativeTimeFromTimestamp(message.created_at)}</div>
                </div>
            </motion.div>
        )
    }, []);
    
    const renderUserEnquiryCategory = useCallback(() => {
        if (data.list.length > 0 || isPending || isFetching) return null;

        return (
            <motion.div
                initial={{ transform: "translateX(100%)" }}
                animate={{ transform: "translateX(0)" }}
                className={classes.messageListEnquiryContainerWrapper}
            >
                <div className={classes.messageListEnquiryContainer}>
                    {ENQUIRY_TAGS.map((tag) => (
                        <button 
                            className={classes.messageListEnquiry}
                            key={tag.label}
                            onClick={() => handleSubmitQuery(tag.command)}
                        >
                            {tag.label}
                        </button>
                    ))}
                </div>
            </motion.div>
        )
    }, [data.list, isFetching, handleSubmitQuery, isPending]);

    const renderUserMessage = useCallback((message) => {
        return (
            <motion.div
                key={message.id}
                initial={{ transform: "translateX(100%)" }}
                animate={{ transform: "translateX(0)" }}
                className={classes.messageListUserItem}
            >
                <div className={classes.messageListUserItemWrapper}>
                    {message.content[0].text.value}
                </div>
                <div className={classes.messageListUserItemDetails}>You - {getRelativeTimeFromTimestamp(message.created_at)}</div>
            </motion.div>
        )
    }, []);

    const errorMessage = useMemo(() => {
        if (!error) return null;

        if (axios.isAxiosError(error)) return error.response?.data.message ?? error.message;

        return error.message;
    }, [error]);

    useEffect(() => {
        if (!isFetching && data.list.length > 0) {
            const lastChild = messageListRef.current?.lastElementChild;
            if (lastChild) lastChild.scrollIntoView({ behavior: 'smooth' });
        }
    }, [data, isFetching]);

    return ( 
        <div className={classes.messageListContainer}>
            <div className={classes.messageListContainerHeader}>
                <div className={classes.messageListContainerHeaderLabel}>
                    <img src={logoWhite} alt="CAP" className={classes.messageListLogo} />
                </div>
            </div>

            <ScrollToBottom className={classes.messageListContent}>
                <div className={classes.messageListBanner}>
                    <div className={classes.messageListBannerTitle}>We are here to assist you. You can also send us an email - info@capplc.com</div>
                    <BsInfoCircle className={classes.messageListBannerIcon}/>
                </div>

                {renderCAPMessage(INITIAL_CHAT_DETAILS)}

                {renderUserEnquiryCategory()}
                
                {data.list.map((message) => message.role === 'assistant' ? renderCAPMessage(message) : renderUserMessage(message))}
                
                {isFetching && renderCAPTyping()}

                <ChatBotScrollButton />
            </ScrollToBottom>

            <ChatBotInput 
                onSubmit={handleSubmitQuery} 
                errorMessage={errorMessage} 
                isLoading={isPending || isFetching} 
            />
        </div>
    );
};
 
export default ChatMessageList;