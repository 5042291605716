import { useCallback, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import { denyAllConsent, mapUserConsentFromBooleanToConstant, updateUserConsent } from "../../services/analytics";
import { getUserConsent, storeUserConsent } from "../../services/session";

import Popup from "../Popup";
import CookiePreferences from "./cookie-preferences";
import CookieSettings from "./cookie-settings";

import styles from "./style.module.css";

const CookieNotice = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isPreferenceVisible, setPreferenceVisible] = useState(false);
    const [preferences, setPreferences] = useState({
      essential: true,
      functional: true,
      analytics: true,
      marketing: true,
      external: true,
    });
  
    const handleSavePreference = useCallback(() => {
      const userConsent = mapUserConsentFromBooleanToConstant(preferences);
  
      storeUserConsent(userConsent);
      updateUserConsent(userConsent);
      
      setPopupVisible(false);
    }, [preferences]);

    const handlePopupClose = useCallback(() => {
        setPopupVisible(false);
    }, []);
  
    const handlePreferenceChange = useCallback((key) => {
      setPreferences((previous) => ({ ...previous, [key]: !previous[key] }));
    }, []);
  
    const togglePreferenceVisibility = useCallback(() => {
      setPreferenceVisible((previous) => !previous);
    }, []);

    useEffect(() => {
        function initializeSettings() {
            const userConsent = getUserConsent();
            if (userConsent) return updateUserConsent(userConsent);

            denyAllConsent();
            setPopupVisible(true);
        }
        
        initializeSettings();
      }, []);

    return ( 
        <Popup isActive={isPopupVisible} onClosePopup={handlePopupClose}>
            <div className={styles.container}>
                <AnimatePresence>
                    {isPreferenceVisible 
                        ? <CookiePreferences preferences={preferences} onChangePreference={handlePreferenceChange} />
                        : <CookieSettings />}
                </AnimatePresence>

                <div className={styles.actions}>
                    <button className={styles.settings} onClick={togglePreferenceVisibility}>
                        Cookie Settings
                    </button>
                    <button className={styles.accept} onClick={handleSavePreference}>
                        Accept Cookies
                    </button>
                </div>
            </div>
        </Popup>
     );
};
 
export default CookieNotice;